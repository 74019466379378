/*
 * @NOTE: Prepend a `~` to css file paths that are in your node_modules
 *        See https://github.com/webpack-contrib/sass-loader#imports
 */

@tailwind base;
@tailwind components;
@tailwind utilities;
@import '~stream-chat-react/dist/css/v2/index.css';
@import 'add-to-calendar-button/assets/css/atcb.css';
/* @import './CustomMessage.scss'; */
/* @import './Chat.scss'; */

/* open-sans-regular - latin */
@font-face {
  font-family: 'Readex Pro';
  font-style: normal;
  font-weight: 300;
  src: local(''),
    url('resources/fonts/readex-pro-v10-latin-300.woff') format('woff'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('resources/fonts/readex-pro-v10-latin-300.woff2') format('woff2'),
}

@font-face {
  font-family: 'Readex Pro';
  font-style: normal;
  font-weight: 200;
  src: local(''),
    url('resources/fonts/readex-pro-v10-latin-200.woff') format('woff'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('resources/fonts/readex-pro-v10-latin-200.woff2') format('woff2'),
}

@font-face {
  font-family: 'Readex Pro';
  font-style: normal;
  font-weight: 500;
  src: local(''),
    url('resources/fonts/readex-pro-v10-latin-500.woff') format('woff'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('resources/fonts/readex-pro-v10-latin-500.woff2') format('woff2'),
  }

@font-face {
  font-family: 'Readex Pro';
  font-style: normal;
  font-weight: 600;
  src: local(''),
    url('resources/fonts/readex-pro-v10-latin-600.woff') format('woff'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('resources/fonts/readex-pro-v10-latin-600.woff2') format('woff2'),
  }

@font-face {
  font-family: 'Readex Pro';
  font-style: normal;
  font-weight: 700;
  src: local(''),
    url('resources/fonts/readex-pro-v10-latin-700.woff') format('woff'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('resources/fonts/readex-pro-v10-latin-700.woff2') format('woff2'),
  }

@font-face {
  font-family: 'Readex Pro';
  font-style: normal;
  font-weight: 400;
  src: local(''),
    url('resources/fonts/readex-pro-v10-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('resources/fonts/readex-pro-v10-latin-regular.woff') format('woff');
}

html {
  @apply font-body;
}

.drag {
  -webkit-app-region: drag;
}

.no-drag {
  -webkit-app-region: no-drag;
}
*::-webkit-scrollbar {
  @apply w-2 h-2 bg-transparent;
}

*::-webkit-scrollbar-track {
  @apply bg-transparent;
}

*::-webkit-scrollbar-thumb {
  @apply border-0 bg-gray-3;
  @apply rounded-full;
}

*::-webkit-scrollbar-thumb:hover {
  @apply bg-gray-4;
}

*::-webkit-scrollbar-corner {
  @apply bg-transparent;
}

.gleap-frame-container {
  bottom: 20px !important;
}


.str-chat {
  --str-chat__background-color: inherit;
  /* --str-chat__primary-overlay-color: ; */
  /* input box background */
  --str-chat__secondary-background-color: inherit;
  --str-chat__font-family: 'Readex Pro';
  --str-chat__primary-color: #3f8cff;
  /* like the typing indicator background */
  --str-chat__overlay-color: transparent;
  --str-chat__spacing-1_5: 0.20rem;
  --str-chat__spacing-1: 0.1rem;
  --str-chat__subtitle-text: 0.9rem/1rem var(--str-chat__font-family);
  --str-chat__spacing-2: 0.3rem;
  --str-chat__spacing-10: 1.5rem;
  @apply z-0 pt-2
}

.react-multi-email.empty > span {
  @apply ml-3 !flex !items-center !text-gray-3
}

.react-multi-email {
  @apply !flex !items-center !justify-center
}

.react-multi-email > input {
  @apply !bg-neutral-3/50  !h-[32px]
}


.atcb-list {
  @apply font-[Readex_Pro]
}


/*
str-chat__main-panel {
  @apply flex justify-end gap-1 p-0;
}
*/

.str-chat__typing-indicator {
  font-size: 10px;
  @apply absolute;
}

.str-chat__message-input .str-chat__message-input-inner .str-chat__file-input-container {
  @apply h-full pt-2;
}

.str-chat__attachment-list {
  --str-chat__attachment-max-width: 300px;

}


.str-chat-channel-list {
  height: fit-content;
  margin-bottom: 8px;
}

.str-chat-channel {
  @apply flex w-full h-full absolute bottom-0 pb-2 bg-inherit z-10
}

.str-chat__li .avatar {
  @apply invisible h-0 w-[34px]
}

.str-chat__li .username {
  @apply hidden
}

.str-chat__li--top .avatar {
  @apply visible pt-2
}

.str-chat__li--single .avatar {
  @apply visible pt-2
}


.str-chat__li--top .username {
  @apply flex items-center justify-center
}

.str-chat__li--single .username {
  @apply flex
}

.str-chat__date-separator {
  @apply py-2
}

/* .str-chat__message-text-inner p {
  font-family: Helvetica Neue, sans-serif;
   font-size: 15px;
  line-height: 18px;
  color: #000000;
  margin-bottom: 0;
} */

/* For some reason our App.css is overriding this and we have to re-set it manually */
.str-chat a {
  @apply text-blue-primary
}


/* Copied from react-multi-email */
.react-multi-email {
  margin: 0;
  max-width: 100%;
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  outline: 0;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  text-align: left;
  line-height: 1.21428571em;
  padding: 0.4em 0.5em;
  /* background: #fff; */
  /* border: 1px solid rgba(34, 36, 38, 0.15); */
  color: rgba(0, 0, 0, 0.87);
  border-radius: 0.28571429rem;
  -webkit-transition: box-shadow 0.1s ease, border-color 0.1s ease;
  transition: box-shadow 0.1s ease, border-color 0.1s ease;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: flex-start;
}
.react-multi-email > span[data-placeholder] {
  display: none;
  position: absolute;
  left: 0.5em;
  top: 0.4em;
  padding: 0.4em;
  line-height: 1.21428571em;
}
.react-multi-email.focused {
  border-color: #85b7d9;
  /* background: #fff; */
}

.react-multi-email.empty > span[data-placeholder] {
  display: inline;
  color: #ccc;
}
.react-multi-email.focused > span[data-placeholder] {
  display: none;
}

.react-multi-email > input {
  flex: 1;
  width: 20rem !important;
  outline: none !important;
  border: 0 none !important;
  display: inline-block !important;
  line-height: 1;
  vertical-align: baseline !important;
  /* padding: 0.4em 0.1em !important; */
  border-radius: 0.75rem;
  padding-left: 0.5rem;
  padding-right: 2rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  background-color: #f5f5f5;
}

.react-multi-email .data-labels {
  flex-wrap: wrap-reverse;
  overflow-y: scroll;
  max-height: 4em;
  margin-bottom: 1rem;
}

.react-multi-email [data-tag] {
  line-height: 1;
  vertical-align: baseline;
  margin: 0.14285714em;
  background-color: #f8fbff;
  background-image: none;
  padding: 0.4em 0.8em;
  color: rgba(0, 0, 0, 0.6);
  text-transform: none;
  font-weight: 600;
  border: 0 solid transparent;
  border-radius: 0.28571429rem;
  -webkit-transition: background 0.1s ease;
  -o-transition: background 0.1s ease;
  transition: background 0.1s ease;
  font-size: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  max-width: 100%;
}

.react-multi-email [data-tag] [data-tag-item] {
  max-width: 100%;
  overflow: hidden;
}
.react-multi-email [data-tag]:first-child {
  margin-left: 0;
}
.react-multi-email [data-tag] [data-tag-handle] {
  margin-left: 0.833em;
  cursor: pointer;
}
/* end copy */
